var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('b-form',{staticClass:"mb-4",attrs:{"inline":""}},[_c('label',{staticClass:"mr-2",attrs:{"for":"dateInput"}},[_vm._v("from")]),_c('b-form-input',{staticClass:"mr-2",attrs:{"id":"dateInput","type":"date","required":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('label',{staticClass:"mr-2",attrs:{"for":"dateInput"}},[_vm._v("to")]),_c('b-form-input',{staticClass:"mr-2",attrs:{"id":"dateInput","type":"date","required":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.load}},[_vm._v("Update Date")]),_c('b-form-select',{staticClass:"mr-2",attrs:{"options":_vm.userTypeOptions},on:{"change":_vm.load},model:{value:(_vm.userType),callback:function ($$v) {_vm.userType=$$v},expression:"userType"}}),_c('label',{staticClass:"mr-2",attrs:{"for":"dateInput"}},[_vm._v("No. of Results")]),_c('b-form-select',{staticClass:"mr-2",attrs:{"options":_vm.numResultsOptions},on:{"change":_vm.load},model:{value:(_vm.numResults),callback:function ($$v) {_vm.numResults=$$v},expression:"numResults"}})],1)],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',[_c('b-card',[_c('h4',[_vm._v("Top "+_vm._s(this.numResults)+" Publications ")]),_c('b-table',{staticStyle:{"font-size":"12px"},attrs:{"id":"table","striped":"","items":_vm.publicationResults,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'NewsItem', params: { id: item.id }}}},[_vm._v(_vm._s(item.name))])]}}])})],1)],1),_c('b-col',[_c('b-card',[_c('h4',[_vm._v("Top "+_vm._s(this.numResults)+" Articles ")]),_c('b-table',{staticStyle:{"font-size":"12px"},attrs:{"id":"table","striped":"","items":_vm.articleResults,"fields":_vm.fieldsArticle},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Article', params: { id: item.id }}}},[_vm._v(_vm._s(item.title))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-card',[_c('h4',[_vm._v("Top "+_vm._s(this.numResults)+" Topics ")]),_c('b-table',{staticStyle:{"font-size":"12px"},attrs:{"id":"table","striped":"","items":_vm.topicResults,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Topic', params: { id: item.id }}}},[_vm._v(_vm._s(item.name))])]}}])})],1)],1),_c('b-col',[_c('b-card',[_c('h4',[_vm._v("Top "+_vm._s(this.numResults)+" Tags ")]),_c('b-table',{staticStyle:{"font-size":"12px"},attrs:{"id":"table","striped":"","items":_vm.tagResults,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'NewsTag', params: { id: item.id}}}},[_vm._v(_vm._s(item.name))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }