<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form inline class="mb-4">
          <label class="mr-2" for="dateInput">from</label>
          <b-form-input id="dateInput" class="mr-2" type="date" v-model="startDate" required> </b-form-input>
          <label class="mr-2" for="dateInput">to</label>
          <b-form-input id="dateInput" class="mr-2" type="date" v-model="endDate" required> </b-form-input>
          <b-form-select class="mr-2" v-model="userType" :options="userTypeOptions" />
          <b-form-select class="mr-2" v-model="field" :options="fieldOptions" />
          <b-button variant="primary" v-on:click="reload">Apply</b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div id="chart" style="width: 80%; height: 500px;"></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts'
const theme = { color: ['#00338D', '#005EB8', '#0091DA', '#483698', '#470A68', '#6D2077', '#00A3A1', '#009A44', '#43B02A', '#EAAA00'] }
echarts.registerTheme('kpmg', theme)

export default {
  created: function () {
    this.load()
  },
  data () {
    return {
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      field: 'all',
      fieldOptions: [
        { value: 'all', text: 'all activities' },
        { value: 'users', text: 'users' },
        { value: 'pages', text: 'pages' },
        { value: 'actions', text: 'actions' },
        { value: 'models', text: 'models' }
      ],
      userType: 0,
      userTypeOptions: [
        { value: 0, text: 'internal users' },
        { value: 1, text: 'external users' }
      ]
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('loadNewsRisks started')
      this.loadingNewsRisk = true
      try {
        const apiName = 'cosmos'
        const path = `/stats/timeseries/${this.userType}/${this.field}/${this.startDate}/${this.endDate}`
        const graphData = await this.$Amplify.API.get(apiName, path)
        const optionTrend = {
          dataset: {
            source: graphData
          },
          xAxis: {
            type: 'time'
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: 'timeseries',
              type: 'line',
              smooth: true,
              encode: {
                y: 'count',
                x: 'date'
              }
            }
          ]
        }
        echarts.init(document.getElementById('chart'), 'kpmg').setOption(optionTrend)
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    reload: function () {
      echarts.dispose(document.getElementById('chart'))
      this.load()
    }
  }
}
</script>

<style></style>
